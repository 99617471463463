/* JS */
/* react */
import React from 'react';
import Lottie from 'react-lottie';

import AOS from 'aos';

import ATLayout from '../components/ATLayout';
import ATPartner from '../components/ATPartner';
import Hamburger from '../components/Hamburger/Hamburger';
import OurNumbers from '../components/OurNumbers/OurNumbers';
import ButtonNext from '../components/Button/ButtonNext';
import SEO from '../components/seo';
import Footer2020 from '../components/Footer2020/Footer2020';
import Footer from '../components/Footer/Footer'






import * as animationData from '../animations/space.json';



import 'aos/dist/aos.css';

import './servizi.css';

const satellite = require('../images/satellite.svg');
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData.default,

};
const PARTNERS = [
	{
		name: 'Eurispes',
		link: 'https://eurispes.eu/',
		imageUrl: require('../images/partners/eurispes.png'),
	},
	{
		name: 'Ragioneria Generale dello Stato',
		link: 'https://www.mef.gov.it/index.html',
		imageUrl: require('../images/partners/ragioneria.png'),
	},
	{
		name: 'Parts & Services',
		link: 'https://www.parts-services.it/',
		imageUrl: require('../images/partners/partsservice.png'),
	},
	{
		name: 'Toyota',
		link: 'https://www.toyota.it/',
		imageUrl: require('../images/partners/toyota.png'),
	},
	{
		name: 'OroDolci',
		link: 'https://www.orodolci.it',
		imageUrl: require('../images/partners/orodolci.png'),
	},
	{
		name: 'Molendini',
		link: 'https://www.instagram.com/molendini.ig/?hl=it',
		imageUrl: require('../images/partners/molendini.png'),
	},
	{
		name: 'Sushi-ii',
		link: 'https://www.instagram.com/sushiiilamezia/?hl=it',
		imageUrl: require('../images/partners/sushiii.png'),
	},
	{
		name: 'TEDXLuiss',
		link: 'https://tedxluiss.it/',
		imageUrl: require('../images/partners/tedxluiss.png'),
	},
	{
		name: 'IperDesign',
		link: 'https://www.iperdesign.com/it/',
		imageUrl: require('../images/partners/iperdesign.png'),
	},
	{
		name: 'Quinaryo',
		link: '',
		imageUrl: require('../images/partners/quinaryo.png'),
	},
	{
		name: 'Ex Trappeto',
		link: 'http://www.extrappeto.it/',
		imageUrl: require('../images/partners/extrappeto.png'),
	},
	{
		name: 'Eneide',
		link: 'http://eneide.mx/',
		imageUrl: require('../images/partners/eneide.png'),
	},
	{
		name: 'Roma Tre',
		link: 'http://www.uniroma3.it/',
		imageUrl: require('../images/partners/romatre.svg'),
	},
	{
		name: 'Run For',
		link: 'https://www.facebook.com/niklamezia/',
		imageUrl: require('../images/partners/runfor.svg'),
	},
	{
		name: 'SMASH',
		link: 'http://www.smashofficial.com/',
		imageUrl: require('../images/partners/smash.svg'),
	},
	{
		name: 'Color Fest',
		link: 'https://www.facebook.com/colorfestlamezia/',
		imageUrl: require('../images/partners/colorfest.svg'),
	},
	{
		name: 'Clafin',
		link: 'https://www.clafin.it/',
		imageUrl: require('../images/partners/clafin.svg'),
	},
	{
		name: 'TakeYourCrypto',
		link: 'https://takeyourcrypto.tech/',
		imageUrl: require('../images/partners/tyc.svg'),
	},
	{
		name: 'Mirror',
		link: 'https://www.mirrorme.it/',
		imageUrl: require('../images/partners/mirror.svg'),
	},
	{
		name: 'FRAC Festival',
		link: 'https://www.fracfestival.com',
		imageUrl: require('../images/partners/frac.svg'),
	},
];

class ServicesPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isMenuOpen: false,
			selectedService: '',
			width: 0,
		};

		this.handleOnHamburgerClick = this.handleOnHamburgerClick.bind(this);

		this.handleOnServiceClick = this.handleOnServiceClick.bind(this);
		this.handleOnServiceItemClick = this.handleOnServiceItemClick.bind(this);

		this.handleOnArrowMouseEnter = this.handleOnArrowMouseEnter.bind(this);
		this.handleOnArrowMouseLeave = this.handleOnArrowMouseLeave.bind(this);

		this.handleOnOutsideMenuClick = this.handleOnOutsideMenuClick.bind(this);

		this.handleOnWindowSizeChange = this.handleOnWindowSizeChange.bind(this);
	}

	componentDidMount() {
		this.handleOnWindowSizeChange();
		window.addEventListener('resize', this.handleOnWindowSizeChange);
		window.scrollTo(0, 0)

		AOS.init();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleOnWindowSizeChange);
	}

	componentDidUpdate() {
		AOS.refresh();
	}

	handleOnHamburgerClick(event) {
		let imo = this.state.isMenuOpen;
		this.setState({
			isMenuOpen: !imo,
		});
	}

	handleOnArrowMouseEnter(event) {
		let arrow = event.target;
		arrow.parentNode.getElementsByTagName('h6')[0].classList = 'service-button-text animated fadeInRight';

		/*if (text) {
			var className = "fadeOutRight";
			var className2 = "fadeInRight";

			text.classList.remove(className);
			text.classList.add(className2);
		}*/
	}

	handleOnArrowMouseLeave(event) {
		let arrow = event.target;
		arrow.parentNode.getElementsByTagName('h6')[0].classList = 'service-button-text animated fadeOutRight';

		/*if (text) {
			var className = "fadeInRight";
			var className2 = "fadeOutRight";

			text.classList.remove(className);
			text.classList.add(className2);
		}*/
	}

	handleOnServiceClick(event) {
		let service = event.currentTarget.parentNode.parentNode.parentNode.getAttribute('data-service');
		if (service && this.state.selectedService !== service)
			this.setState(
				{
					selectedService: service,
					selectedServiceItem: 0,
				},
				() => AOS.refresh()
			);
		else
			this.setState(
				{
					selectedService: '',
				},
				() => AOS.refresh()
			);
	}

	handleOnServiceItemClick(event) {
		const li = event.currentTarget;
		const parent = li.parentNode;
		const index = Array.prototype.indexOf.call(parent.children, li);
		this.setState({ selectedServiceItem: index });
	}

	handleOnOutsideMenuClick(event) {
		console.log('test')
		this.setState({
			isMenuOpen: false,
		});
	}

	handleOnWindowSizeChange() {
		this.setState({ width: window.innerWidth });
	}

	render() {
		const isMobile = this.state.width <= 812;

		return (
			<ATLayout title="Servizi" isMenuOpen={this.state.isMenuOpen} onOutsideMenuClick={this.handleOnOutsideMenuClick}>
				<SEO
					title="Servizi web e di design di Alfatauri Studio"
					relPath="servizi"
					description="Forniamo il miglior design e le applicazioni più utili basando la nostra visione sulla raccolta di idee e esigenze, valutate insieme a voi."
				/>
				<div className="at-page">
					{this.state.width >=1024 &&
					<Lottie
					 
					options={defaultOptions}
						width={'30vw'}
						style={{ position: 'fixed', right: '0', margin:'unset', height: 'unset', top:'0'  }}
					></Lottie> }
				{/*<img className="image-satellite" src={satellite} alt="Satellite" />  */}	
					<div className="container heading">
						<div className="row">
							<div className="col-lg-8">
								<div className="title">
									<h1>
										Servizi<span style={{ color: '#EF6A1B' }}>.</span>
									</h1>
									<p>
										Alfatauri Studio è una web agency che basa la sua visione sulla raccolta di idee e/o esigenze, che vengono valutate e
										analizzate insieme al cliente, arrivando a un processo di linearizzazione che porta all’identificazione dei servizi di
										cui il cliente ha bisogno.
										<br />
										Crediamo nel <b>gioco di squadra</b> e in tutto ciò che questo può scaturirne. <b>Anche voi</b> ne fate parte
										<span style={{ color: 'orange' }}>.</span>
									</p>
								</div>
								{/* <h3 className="subtitle">
									Crediamo nel <b>gioco di squadra</b> e in tutto ciò che questo può scaturirne. <b>Anche voi</b> ne fate parte<span style={{ color: 'orange' }}>.</span>
								</h3> */}
							</div>
						</div>
					</div>

					<div
						className={'container service ' + (this.state.selectedService === 'branding' ? 'selected' : '')}
						data-service="branding"
						data-aos="fade-right"
					>
						<div className="row">
							<div className="col-12">
								<h2 className="service-title">
									Branding<span style={{ color: '#EF6A1B' }}>.</span>
								</h2>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<p className={'service-descr animated ' + (this.state.selectedService === 'branding' ? 'fadeOutLeft' : 'fadeInLeft')}>
									Attraverso questo processo ci impegniamo a gestire la <b>realizzazione</b> del vostro brand. Partendo dalla parte
									progettuale diamo vita alle vostre idee attraverso <i>immagini</i>,<i>simboli</i> e <i>colori</i> e le curiamo nel tempo. La
									doppia finalità di promozione (<b>brand image</b>) e di incentivare la fedeltà dei clienti (<b>brand loyalty</b>) aiuterà il
									vostro brand ad acquisire sempre più <b>forza</b>.
								</p>

								<div className="service-content container">
									<div className="row">
										<ul className="col-12 col-lg-3 service-content-list">
											<li className={this.state.selectedServiceItem === 0 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Briefing<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 1 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Analisi<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 2 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Strategia<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 3 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Sviluppo<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 4 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Promozione<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 5 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Misurazione<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
										</ul>
										<div
											className={
												'col-12 col-lg-6 d-inline-block animated ' +
												(this.state.selectedService !== 'branding' ? 'fadeOutRight' : 'fadeInRight')
											}
										>
											<p
												id="briefing-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 0 ? 'fadeInRight' : 'd-none')}
											>
												In questa fase si acquisiscono dal cliente tutti i dati e le informazioni utili per definire il progetto da
												sviluppare. Sin dal primo approccio è necessario comprendere le esigenze del committente e fornire soluzioni su
												misura, noi ci proponiamo di tradurre le idee in obiettivi da realizzare.
											</p>
											<p
												id="analisi-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 1 ? 'fadeInRight' : 'd-none')}
											>
												Il passo successivo al briefing serve a individuare con un’attività di analisi e un benchmark di settore, i
												punti di forza e debolezza del progetto, definire i buyer personas e il loro processo di acquisto, stabilire i
												competitors da battere. Tutte informazioni che serviranno per poter successivamente definire la strategia da
												adottare.
											</p>
											<p
												id="strategia-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 2 ? 'fadeInRight' : 'd-none')}
											>
												Questo è il momento dedicato alla definizione della strategia e delle relative attività di web marketing. Step
												obbligato che serve a coordinare l’intero processo, a scegliere le aree di intervento, a stabilire gli
												investimenti, KPI di misurazione e obiettivi. Grazie ad un’accurata progettazione siamo in grado di fornire le
												soluzioni migliori per un’azienda.
											</p>
											<p
												id="sviluppo-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 3 ? 'fadeInRight' : 'd-none')}
											>
												Per sviluppo intendiamo le attività di creatività, web design, development, produzione di contenuti di valore e
												potenziamento della brand identity; in altre parole, la realizzazione di un prodotto convincente sotto il
												profilo estetico, accessibile da dispositivi differenti, un mezzo che comunica “chi sei” con stile ed efficacia
												in termini di impatto sugli utenti in target.
											</p>
											<p
												id="promozione-text"
												className={' service-item-descr animated ' + (this.state.selectedServiceItem === 4 ? 'fadeInRight' : 'd-none')}
											>
												Per portare un business al successo oggi più che mai sono necessarie attività di internet marketing ben
												congeniate. Le azioni e gli strumenti che servono ad acquisire clienti possono essere molteplici e variare in
												base alle esigenze e alle caratteristiche del progetto. SEM, Advertising Online, Digital PR, Direct Email
												marketing, Social e Content Marketing, questi sono gli assi vincenti di Secret Key.
											</p>
											<p
												id="misurazione-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 5 ? 'fadeInRight' : 'd-none')}
											>
												Se non si conoscono le performance del proprio business online non è possibile capire se si stanno raggiungendo
												gli obiettivi prefissati e non è possibile migliorare il rendimento degli investimenti. Noi ci occupiamo di
												stabilire dei KPI, poi monitoriamo, misuriamo e ottimizziamo le varie azioni e campagne di Web Marketing,
												massimizzando il ROI.
											</p>
										</div>
									</div>
								</div>

								<div className="service-button" onClick={this.handleOnServiceClick}>
									{!isMobile && <ButtonNext />}

									<h6
										className={
											'service-button-text animated ' +
											(isMobile ? (this.state.selectedService === 'branding' ? 'fadeOutLeft' : 'fadeInLeft') : '')
										}
									>
										Il nostro metodo
									</h6>

									{isMobile && <ButtonNext />}
								</div>
							</div>
						</div>
					</div>

					<div
						className={'container service ' + (this.state.selectedService === 'design' ? 'selected' : '')}
						data-service="design"
						data-aos="fade-right"
					>
						<div className="row">
							<div className="col-12">
								<h2 className="service-title">
									Web Design<span style={{ color: '#EF6A1B' }}>.</span>
								</h2>
							</div>
						</div>

						<div className="row ">
							<div className="col-12">
								<p className={'service-descr animated ' + (this.state.selectedService === 'design' ? 'fadeOutLeft' : 'fadeInLeft')}>
									Ci occupiamo del processo di <b>progettazione visiva</b> del vostro sito o della vostra web app, garantendo un prodotto{' '}
									<i>chiavi in mano</i> che coinvolga i visitatori del sito attraverso un aspetto grafico accattivante e funzionale, frutto di
									una ricerca e un analisi su
									<b>interfaccia</b> ed <b>usabilità</b>. Il nostro obiettivo è rendere semplice la navigazioni andando incontro alle vostre
									esigenze e a quelle degli utenti <i>rendendo ogni prodotto accessibile</i> da browser e dispositivi differenti.
								</p>

								<div className="service-content container">
									<div className="row">
										<ul className="col-12 col-lg-3 service-content-list">
											<li className={this.state.selectedServiceItem === 0 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Briefing<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 1 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Analisi<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 2 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Strategia<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 3 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Modello<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 4 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Design<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
										</ul>
										<div
											className={
												'col-12 col-lg-6 d-inline-block animated ' +
												(this.state.selectedService !== 'design' ? 'fadeOutRight' : 'fadeInRight')
											}
										>
											<p
												id="briefing-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 0 ? 'fadeInRight' : 'd-none')}
											>
												In questa fase si acquisiscono dal cliente tutte le informazioni utili per definire il progetto da sviluppare.
												E' necessario comprendere le esigenze del committente e fornire soluzioni su misura.
											</p>

											<p
												id="analisi-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 1 ? 'fadeInRight' : 'd-none')}
											>
												Il passo successivo al briefing è utile a studiare il mercato e il settore di riferimento. Identificare il
												segmento di target inteso come clienti e potenziali clienti.
											</p>

											<p
												id="strategia-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 2 ? 'fadeInRight' : 'd-none')}
											>
												Qui va elaborata una strategia di marketing per definire le linee guida della realizzazione di un sito web
												orientato alla conversione. Un sito web progettato senza una strategia non porta a risultati.
											</p>

											<p
												id="sviluppo-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 3 ? 'fadeInRight' : 'd-none')}
											>
												L'ideazione dell'architettura del tuo sito web è la fase strategica relativa a "cosa, come e dove" inserire gli
												elementi. Tutto ciò necessita di un modello concettuale e strutturale che definisce il comportamento
												dell'utente.
											</p>

											<p
												id="promozione-text"
												className={' service-item-descr animated ' + (this.state.selectedServiceItem === 4 ? 'fadeInRight' : 'd-none')}
											>
												In questa fase si passa al setaccio ogni informazione utile per ideare l'interfaccia grafica del tuo sito web e
												creare quindi il prototipo dell'architettura che abbiamo pianificato in fase strategica (attraverso un
												wireframe). L'obiettivo è quello di offrire un'esperienza di navigazione coinvolgente e fidelizzante.
											</p>
										</div>
									</div>
								</div>

								<div className="service-button" onClick={this.handleOnServiceClick}>
									{!isMobile && <ButtonNext />}

									<h6
										className={
											'service-button-text animated ' +
											(isMobile ? (this.state.selectedService === 'design' ? 'fadeOutLeft' : 'fadeInLeft') : '')
										}
									>
										Il nostro metodo
									</h6>

									{isMobile && <ButtonNext />}
								</div>
							</div>
						</div>
					</div>

					<div className={'container service ' + (this.state.selectedService === 'dev' ? 'selected' : '')} data-service="dev" data-aos="fade-right">
						<div className="row">
							<div className="col-12">
								<h2 className="service-title">
									Web Development<span style={{ color: '#EF6A1B' }}>.</span>
								</h2>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<p className={'service-descr animated ' + (this.state.selectedService === 'dev' ? 'fadeOutLeft' : 'fadeInLeft')}>
									È qui che <b>progettiamo</b> tecnicamente e <b>sviluppiamo</b> la struttura e l'insieme dei processi automativi di un sito
									web o di una web app. Facciamo questo attraverso l'<b>individuazione </b>
									delle <i>soluzioni tecnologiche</i> migliori e delle <b>scelte</b> <i>di programmazione </i>
									più adatte. Ci impegniamo nella <b>gestione</b> e conseguentemente della sicurezza della piattaforma, ci occupiamo di{' '}
									<i>trasformare un idea in un prodotto</i> reale ed utilizzabile da voi e dai vostri utenti.
								</p>

								<div className="service-content container">
									<div className="row">
										<ul className="col-12 col-lg-3 service-content-list">
											<li className={this.state.selectedServiceItem === 0 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Analisi<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 1 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Progettazione<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 2 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Sviluppo<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 3 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Testing<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
											<li className={this.state.selectedServiceItem === 4 ? 'active' : ''} onClick={this.handleOnServiceItemClick}>
												<h5 className="underline">
													Manutenzione<span style={{ color: '#EF6A1B' }}>.</span>
												</h5>
											</li>
										</ul>
										<div
											className={
												'col-12 col-lg-6 d-inline-block animated ' +
												(this.state.selectedService !== 'dev' ? 'fadeOutRight' : 'fadeInRight')
											}
										>
											<p
												id="briefing-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 0 ? 'fadeInRight' : 'd-none')}
											>
												L'analisi dei requisiti consiste nella individuazione delle caratteristiche statiche (dei dati) e dinamiche
												(delle operazioni) dell'applicazione da realizzare. I requisiti vengono raccolti in specifiche espresse in
												linguaggio naturale. I requisiti dell'applicazione provengono da diverse fonti, tra le quali: gli utenti
												dell'applicazione, la documentazione esistente attinente al problema allo studio, eventuali realizzazioni
												preesistenti dell'applicazione.
											</p>
											<p
												id="analisi-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 1 ? 'fadeInRight' : 'd-none')}
											>
												Sulla base dei risultati raccolti prodotti dall'analisi dei requisiti, il progetto definisce come tali requisiti
												saranno soddisfatti, entrando nel merito della struttura che dovrà essere data al sistema software che deve
												essere realizzato. La progettazione rimane comunque una fase distinta dalla programmazione o codifica, che
												corrisponde alla traduzione in un particolare linguaggio di programmazione delle decisioni prese in sede di
												progettazione, cioè dunque la fase implementativa.
											</p>
											<p
												id="strategia-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 2 ? 'fadeInRight' : 'd-none')}
											>
												Si intende precisamente l’attività di sviluppo e traduzione dei processi descritti nel progetto in componenti
												software. La fase di sviluppo coinvolge spesso numerose tecnologie relative non solo al prodotto, ma anche al
												processo che lo realizza.
											</p>
											<p
												id="sviluppo-text"
												className={'service-item-descr animated ' + (this.state.selectedServiceItem === 3 ? 'fadeInRight' : 'd-none')}
											>
												Il testing del software è un procedimento utilizzato per individuare le carenze di correttezza, completezza e
												affidabilità di un prodotto software in corso di sviluppo. Con tale attività si vuole quindi assicurare la
												qualità del prodotto tramite la ricerca di difetti, ovvero una sequenza di istruzioni e procedure che, quando
												eseguiti con particolari dati di input e in particolari ambienti operativi, generano dei malfunzionamenti.
											</p>
											<p
												id="promozione-text"
												className={' service-item-descr animated ' + (this.state.selectedServiceItem === 4 ? 'fadeInRight' : 'd-none')}
											>
												La manutenzione del software è il processo di modifica di un prodotto dopo il suo rilascio in esercizio per
												eliminare malfunzionamenti, migliorare prestazioni o altri attributi di qualità e adattarlo a modifiche
												dell’ambiente operativo. Il termine manutenzione del software (o evoluzione del software) include spesso anche
												estensioni delle funzionalità originarie per soddisfare nuovi bisogni degli utenti.
											</p>
										</div>
									</div>
								</div>

								<div className="service-button" onClick={this.handleOnServiceClick}>
									{!isMobile && <ButtonNext />}

									<h6
										className={
											'service-button-text animated ' +
											(isMobile ? (this.state.selectedService === 'dev' ? 'fadeOutLeft' : 'fadeInLeft') : '')
										}
									>
										Il nostro metodo
									</h6>

									{isMobile && <ButtonNext />}
								</div>
							</div>
						</div>
					</div>

{/* 
					<div className="container">
						<div className="row">
							<div className="col-12" data-aos="fade-left">
								<OurNumbers></OurNumbers>
							</div>
						</div>
					</div>
*/}
					<div className="container partners">
						<div className="row">
							<div className="col-lg-12">
								<h4 data-aos="fade-right">
									Abbiamo <strong>collaborato</strong> con<span style={{ color: 'rgb(232, 83, 29)' }}>:</span>
								</h4>
							</div>
						</div>

						<div className="row">
							{PARTNERS.map(p => (
								<div className="col-6 col-lg-3" key={p.name}>
									<ATPartner name={p.name} link={p.link} imageUrl={p.imageUrl} />
								</div>
							))}
						</div>
					</div>

					<Hamburger
						open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
						onClick={this.handleOnHamburgerClick}
					/>

					{/*	<Footer links={this.props.footerLinks} onLinkClick={this.props.onFooterLinkClick} /> */}
					<Footer2020 />
				</div>
			</ATLayout>
		);
	}
}

export default ServicesPage;
